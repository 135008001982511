import React from 'react'
import { TbNetworkOff } from 'react-icons/tb'

function Error(props) {
    const error = props.error
    return (
        <div className='alert alert-error rounded-lg mt-4 mb-6'>
            <TbNetworkOff className='h-7 w-7'/>
            <p>{error?.message && (
                <span>{error?.message}</span>
            )}</p>
        </div>
    )
}

export default Error