import React from 'react'
import { Link } from 'react-router-dom'
import { IoMdInformationCircle } from "react-icons/io"
import { RiGalleryFill } from "react-icons/ri"
import { FaPenFancy } from "react-icons/fa"
import logo from '../../assets/logo_short.png'

function NavBar() {
    return (
        <div className='navbar shadow-md mb-5 lg:px-6 sticky top-0 z-30'>
            <div className="md:navbar-start space-x-2 ">
                <label htmlFor="nav-drawer">
                    <div className="btn btn-ghost btn-circle mt-1">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                    </div>
                </label>
                <div className='flex items-center'>
                    <Link className="hidden md:block" to='/'><img className='h-16 w-auto' src={logo} alt="logo" /></Link>
                </div>
            </div>
            <div className="flex md:hidden navbar-end">
                <Link className="" to='/'><img className='h-16 w-auto' src={logo} alt="logo" /></Link>   
            </div>
            <div className="md:navbar-end space-x-2">
                <div className="hidden md:flex">
                    <ul className="menu menu-horizontal">
                        <li>
                            <Link to='/about'><IoMdInformationCircle className='h-5 w-5 text-primary'/> About</Link>
                        </li>
                        <li>
                            <Link to='/gallery'><RiGalleryFill className='h-5 w-5 text-primary'/> Gallery</Link>
                        </li>
                        <li>
                            <Link to='/contact'><FaPenFancy className='h-5 w-5 text-primary'/> Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default NavBar