import React from 'react'
import utils from '../../utils'

function Pagination(props) {
    const next = props.next
    const previous = props.previous
    const isAdmin = props.isAdmin

    function changePage(page){
        props.changePageParam(page)
    }
    return (
        <div className='flex justify-center pt-3 mb-3 mt-4'>
            <div className="join border">
                {previous && (
                    <button 
                        onClick={() => changePage(utils.getPageValue(previous))}
                        className={isAdmin ? "join-item btn btn-sm":"join-item btn btn-primary btn-outline"}>«</button>
                )}
                <button className={isAdmin ? "join-item btn btn-sm":"join-item btn btn-primary btn-outline"}>
                    Page {utils.getCurrentPage(previous, next)}
                </button>
                {next && (
                    <button 
                        onClick={() => changePage(utils.getPageValue(next))}
                        className={isAdmin ? "join-item btn btn-sm":"join-item btn btn-primary btn-outline"}>»</button>
                )}
            </div>
        </div>
    )
}

export default Pagination