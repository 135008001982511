import React from 'react'
import { BsRulers } from "react-icons/bs"
import { FaPlaceOfWorship } from "react-icons/fa6"
import { SiStylelint } from "react-icons/si"
import { IoFastFoodSharp } from "react-icons/io5"
import { GiVideoConference } from "react-icons/gi"
import { FaShop } from "react-icons/fa6"
import { BsFillMusicPlayerFill } from "react-icons/bs"
import { GiFilmProjector } from "react-icons/gi"
import { FaHotel } from "react-icons/fa6"
import { GiSpotedFlower } from "react-icons/gi"
import { GiCakeSlice } from "react-icons/gi"

function About() {
    return (
        <div className='mx-4 md:mx-5 lg:mx-7'>
            <h2 className="text-center text-secondary mb-6 text-3xl font-bold">What we do</h2>
            <p className="text-center mb-6">We provide our Customers with top notch event services including:</p>
            <div className=" grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                <div className="card rounded-md border p-3 hover:shadow">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <BsRulers className='h-6 w-6'/>
                        </div>                        
                    </div>
                    <p className="text-center font-bold">Concept Development</p>
                </div>
                <div className="card rounded-md border p-3 hover:shadow">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <FaPlaceOfWorship className='h-6 w-6'/>
                        </div>    
                    </div>
                    <p className="text-center font-bold">Venue Selection</p>
                </div>
                <div className="card rounded-md border p-3 hover:shadow">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <SiStylelint className='h-6 w-6'/>
                        </div> 
                    </div>
                    <p className="text-center font-bold">Event Styling</p>
                </div>
                <div className="card rounded-md border p-3 hover:shadow">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <IoFastFoodSharp className='h-6 w-6'/>
                        </div> 
                    </div>
                    <p className="text-center font-bold">Food & Beverage Provision</p>
                </div>
                <div className="card rounded-md border p-3 hover:shadow ">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <GiVideoConference className='h-6 w-6'/>
                        </div> 
                    </div>
                    <p className="text-center font-bold">Conference Management</p>
                </div>
                <div className="card rounded-md border p-3 hover:shadow ">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <FaShop className='h-6 w-6'/>
                        </div> 
                    </div>
                    <p className="text-center font-bold">Vendor Scouting & Management</p>
                </div>
                <div className="card rounded-md border p-3 hover:shadow ">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <BsFillMusicPlayerFill className='h-6 w-6'/>
                        </div> 
                    </div>
                    <p className="text-center font-bold">Entertainment Sound & Lighting</p>
                </div>
                <div className="card rounded-md border p-3 hover:shadow ">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <GiFilmProjector className='h-6 w-6'/>
                        </div> 
                    </div>
                    <p className="text-center font-bold">Audio-Visual Services</p>
                </div>
                <div className="card rounded-md border p-3 hover:shadow ">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <FaHotel className='h-6 w-6'/>
                        </div> 
                    </div>
                    <p className="text-center font-bold">Concierges Services</p>
                </div>
                <div className="card rounded-md border p-3 hover:shadow">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <GiSpotedFlower className='h-6 w-6'/>
                        </div> 
                    </div>
                    <p className="text-center font-bold">Floral Arrangements</p>
                </div>
                <div className="card rounded-md border p-3 hover:shadow">
                    <div className="flex justify-center mb-2">
                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                            <GiCakeSlice className='h-6 w-6'/>
                        </div> 
                    </div>
                    <p className="text-center font-bold">Cakes & Pastries</p>
                </div>
            </div>
        </div>
    )
}

export default About