import React from 'react'
import utils from '../../utils'

function Hero() {
    return (
        <div className='mx-1 md:mx-4 lg:mx-7 mt-7 '>
            <div className="hero min-h-screen rounded-md" style={{backgroundImage: `url(${utils.baseUrl}hero_image)`}}>
            <div className=""></div>
                <div className="hero-content text-center">
                    <div className="max-w-md bg-base-100 p-6 rounded-md">
                        <h1 className="mb-5 text-5xl font-bold text-secondary">Hello there</h1>
                        <p className="mb-5">We are Providers of Event Management Solutions, Leasing of Equipment & your Entertainment Partner.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero