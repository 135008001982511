import React from 'react'
import {BsTelephoneFill} from 'react-icons/bs'
import {IoIosMailOpen} from 'react-icons/io'
import { FaLocationDot } from "react-icons/fa6"

function ContactInfo() {
    return (
        <div className='hidden md:block'>
            <div className="flex justify-center py-6 text-white bg-neutral">
                <div className='lg:px-6 grid grid-cols-3 gap-3 text-sm w-10/12 lg:w-7/12'>
                    <div className='flex gap-3 border p-3 rounded-md border-accent items-center'>
                        <div className="grow-0">
                            <div className="border rounded-md border-accent p-2">
                                <BsTelephoneFill className='h-7 w-7 lg:h-6 lg:w-6 text-accent'/>
                            </div>                            
                        </div>
                        <div className='grow'>
                            <p className='font-bold'>Phone:</p>
                            <p>0721817980</p>
                        </div>
                    </div>
                    <div className='flex gap-3  border p-3 rounded-md border-accent items-center'>
                        <div className="grow-0">
                            <div className="border rounded-md border-accent p-2">
                                <IoIosMailOpen className='h-7 w-7 lg:h-6 lg:w-6 text-accent'/>
                            </div>                            
                        </div>
                        <div className='grow'>
                            <p className='font-bold'>Email:</p>
                            <p>info@oriental-occasions.co.ke</p>
                        </div>
                    </div>
                    <div className='flex gap-3 border p-3 rounded-md border-accent items-center'>
                        <div className="grow-0">
                            <div className="border rounded-md border-accent p-2">
                                <FaLocationDot className='h-7 w-7 lg:h-6 lg:w-6 text-accent'/>
                            </div>                            
                        </div>
                        <div className='grow'>
                            <p>Kenya, Kisumu,</p>
                            <p>Off Jairo Street, Kimute Est.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo