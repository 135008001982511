import React, { useRef, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import Loading from '../components/feedback/Loading'
import imageServices from '../services/ImageServices'
import toast from 'react-hot-toast'

function ContactPage() {
    const [loading, setLoading] = useState(false)
    const emailRef = useRef()
    const subjectRef = useRef()
    const textRef = useRef()

    function submitHandler(e){
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append('email', emailRef.current.value)
        formData.append('subject', subjectRef.current.value)
        formData.append('text', textRef.current.value)
        imageServices.addContact(formData).then(
            res => {
                emailRef.current.value = null
                subjectRef.current.value = null
                textRef.current.value = null
                setLoading(false)
                toast.success(res.data?.message)
            }, err => {
                setLoading(false)
                toast.error("Something went wrong")
            }
        )
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Contact Us - {utils.siteName}</title>
            </Helmet>
            <h1 className="text-center text-4xl mb-6 text-secondary">Contact Us</h1>
            <div className="flex justify-center">
                <div className="w-9/12 md:w-6/12 lg:w-5/12">
                    <form onSubmit={submitHandler}>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Email:*</span>
                            </label>
                            <input type="email" required name="email" ref={emailRef} className="input input-bordered w-full "/>
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Subject:</span>
                            </label>
                            <input type="text" name="subject" ref={subjectRef} className="input input-bordered w-full "/>
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Text:*</span>
                            </label>
                            <textarea className="textarea textarea-bordered w-full" ref={textRef} required cols="4" placeholder="Text"></textarea>
                        </div>
                        <div className="flex justify-center mt-5">
                            {loading ? (<Loading/>):(
                                <button className="btn btn-primary" type="submit">Submit</button>
                            )}                            
                        </div>
                    </form>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default ContactPage