import axios from "axios"
import utils from "../utils"

const instance = axios.create({
    baseURL: utils.baseUrl,
    headers: {
        "Content-type": "application/json;charset=UTF-8",
        "Accept": "application/json",
    }
})

export default instance