import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import { GrGremlin } from "react-icons/gr"
import { Link } from 'react-router-dom'
import { IoMdHome } from "react-icons/io"

function PageNotFound() {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Page not Found - {utils.siteName}</title>
            </Helmet>
            <div>
                <h1 className="text-center text-4xl mb-6 text-secondary">Page Not Found</h1>
                <div className='mx-4'>
                <div className="alert items-center rounded-md">
                    <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl">
                        <GrGremlin className='h-9 w-9'/>
                    </div> 
                    <p>Sorry! The Page / Resource you are looking for could not be found.</p>
                    <Link  to='/' className='btn btn-primary btn-wide'><IoMdHome/> Go Back Home</Link>
                </div>
            </div>
            </div>
        </HelmetProvider>
    )
}

export default PageNotFound