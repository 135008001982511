import React from 'react'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { RiGalleryFill } from "react-icons/ri"
import bday from '../../assets/bthday.jpeg'
import chairs from '../../assets/chairs.jpeg'
import tables from '../../assets/tables.jpeg'
import tent from '../../assets/tent.jpeg'
import flowers from '../../assets/flowers.jpeg'
import juice from '../../assets/juices.jpeg'
import chart1 from '../../assets/chairs1.jpeg'
import { Link } from 'react-router-dom'

function Gallery() {
    return (
        <div className='mx-1 md:mx-4 lg:mx-7'>
            <h2 className="text-center text-secondary mb-6 text-3xl font-bold">Gallery</h2>
            <p className="text-center mb-6">Below are a sample of images of our work:</p>
            <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1080: 4}}>
                <Masonry gutter='7.2px'>
                    <div>
                        <img className="rounded-md" src={juice} alt="house and plann"/>
                    </div>
                    <div>
                        <img className="rounded-md" src={bday} alt="house and plann"/>
                    </div>
                    <div>
                        <img className="rounded-md" src={chairs} alt="house and plann"/>
                    </div>
                    <div>
                        <img className="rounded-md" src={tables} alt="house and plann"/>
                    </div>
                    <div>
                        <img className="rounded-md" src={tent} alt="house and plann"/>
                    </div>
                    <div>
                        <img className="rounded-md" src={flowers} alt="house and plann"/>
                    </div>
                    <div>
                        <img className="rounded-md" src={chart1} alt="house and plann"/>
                    </div>
                </Masonry>
            </ResponsiveMasonry>
            <div className="flex justify-center mt-8">
                <Link to='/gallery' className='btn btn-primary btn-wide uppercase' style={{'backgroundColor': '#852595ff'}}><RiGalleryFill/> View more at the Gallery</Link>
            </div>
        </div>
    )
}

export default Gallery