import React from 'react'
import kemri from '../../assets/kemri.png'
import citam from '../../assets/citam.png'
import peace from '../../assets/peacec.png'
import kttc from '../../assets/kttc.jpeg'
import tdx from '../../assets/ydx.png'
import changers from '../../assets/chengers.png'

function PreviousClients() {
    return (
        <div className='mx-1 md:mx-4 lg:mx-7'>
            <div className="rounded-md bg-neutral text-neutral-content py-12">
                <p className="text-accent text-3xl font-bold text-center mb-4">Our Previous Clientele</p>

                <div className="carousel carousel-center p-4 space-x-4 ">
                    <div className="carousel-item">
                        <img src={kemri} alt='kemri logo' className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img src={citam} alt='citam logo' className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img src={peace} alt='peace corpse logo' className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img src={kttc} alt='kemri logo' className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img src={tdx} alt='YDX Agency logo' className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img src={changers} alt='Game changers logo' className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <div className='rounded-box border border-accent p-3'>
                            <p className="font-bold text-2xl mb-2">And so many more including:</p>
                            <ul>
                                <li>Great Lakes University of Kisumu</li>
                                <li>Community Initiatives Social Services</li>
                                <li>Kabete National Polytechnic</li>
                                <li>Sosa Cottages</li>
                                <li>Aherinet Kisumu</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default PreviousClients