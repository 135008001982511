import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Drawer from './components/nav/Drawer';
import NavBar from './components/nav/NavBar';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import GalleryPage from './pages/GalleryPage';
import ContactPage from './pages/ContactPage';
import Footer from './components/nav/Footer';
import { useEffect } from 'react';
import PageNotFound from './pages/PageNotFound';
import { Toaster } from 'react-hot-toast';
import utils from './utils';
import ContactInfo from './components/nav/ContactInfo';

function App() {
  const location = useLocation()
  useEffect(() => {
    const top = document.getElementById("nav-top")
    top.scrollIntoView()
  }, [location.pathname])
  return (
    <div className="App">
      <div className="drawer drawer-end">
        <input id="nav-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <ContactInfo/>
          <div id='nav-top' className=''></div>
          <NavBar/>

          <div className='page'>
            <Routes>
                <Route path='/' element={<HomePage/>}/>
                <Route path='/about' element={<AboutPage/>}/>
                <Route path='/gallery' element={<GalleryPage/>}/>
                <Route path='/contact' element={<ContactPage/>}/>
                <Route path='*' element={<PageNotFound/>}/>          
            </Routes>
          </div>
          
          <Footer/>
        </div>
        <Toaster
          position={utils.toasterPoisition}
          toastOptions={utils.toasterOptions}
        />
        <Drawer/>
      </div>
    </div>
  );
}

export default App;
