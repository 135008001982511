import React from 'react'
import Hero from '../components/home/Hero'
import About from '../components/home/About'
import Gallery from '../components/home/Gallery'
import GetUs from '../components/home/GetUs'
import PreviousClients from '../components/home/PreviousClients'

function HomePage() {
    return (
        <div>
            <div className='mb-12'>
                <Hero/>
            </div>
            <div className='mb-12'>
                <About/>
            </div>
            <div className='mb-12'>
                <GetUs/>
            </div>
            <div className='mb-12'>
                <Gallery/>
            </div>
            <div className='mb-12'>
                <PreviousClients/>
            </div>
        </div>
    )
}

export default HomePage