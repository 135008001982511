import React from 'react'
import {TbMoodEmptyFilled} from 'react-icons/tb'

function Empty(props) {
    return (
        <div className='alert rounded-lg alert-info mt-6'>
            <TbMoodEmptyFilled className='h-7 w-7'/>
            <p>No {props.item} found yet.</p>
        </div>
    )
}

export default Empty