import React from 'react'

function GetUs() {
    return (
        <div className='mx-1 md:mx-4 lg:mx-7'>
            <div className="rounded-md bg-primary py-7" style={{'backgroundColor': '#852595ff'}}>
                <p className="text-white text-3xl font-bold text-center mb-4">Get Us On</p>
                <p className='text-white text-center mb-3'>Mobile: 0721817980 / 0724837227 / 0733817980</p>
                <p className='text-white text-center mb-3'>Email: info@oriental-occasions.co.ke / linda@orientaloccasions.co.ke / bob@orientaloccasions.co.ke</p>
            </div>            
        </div>
    )
}

export default GetUs