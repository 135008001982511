import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import { GiStarsStack } from "react-icons/gi"
import { FaCogs } from "react-icons/fa"
import { FaRegCircleQuestion } from "react-icons/fa6"
import { RiGalleryFill } from "react-icons/ri"
import GetUs from '../components/home/GetUs'
import { Link } from 'react-router-dom'
import {GiAutoRepair} from 'react-icons/gi'
import PreviousClients from '../components/home/PreviousClients'

function AboutPage() {
    return (
        <HelmetProvider>
            <Helmet>
                <title>About Us - {utils.siteName}</title>
            </Helmet>
            <div>
                <h1 className="text-center text-4xl mb-6 text-secondary">About Us</h1>
                <div className="flex justify-center mb-7">
                    <div className="w:9/12 md:w-8/12 lg:7/12 xl:4/12 space-y-6">
                        <p className='text-center'>
                            Oriental Occasions is your all-in-one events management company, providing full 
                            event management and planning, including creation and development of 
                            <span className='font-semibold'> Thematic Concepts</span>, <span className='font-semibold'>Decoration</span>, 
                            <span className='font-semibold'> Hire for Tents</span>, <span className='font-semibold'>Event Furniture</span>, 
                            <span className='font-semibold'> Drapes & Luxury Lines</span>, <span className='font-semibold'>Accessories</span> and  
                            <span className='font-semibold'> Floral</span>.
                        </p>
                        <p className='text-center'>
                            Our extensive work and experience has deemed us the best events management company in the 
                            region. Our elegant yet practical, <span className='font-semibold'>Pergola Tents</span>, 
                            <span className='font-semibold'>Hexagon</span>, <span className='font-semibold'>Dome</span>, 
                            <span className='font-semibold'>Canopies</span>, <span className='font-semibold'>A-Frame</span> and
                            <span className='font-semibold'> B-Line Tents </span>are attractive and create the right atmosphere whether
                            it is a small Family Party, Wedding or Corporate Event.
                        </p>
                        <p className="text-center pb-7">
                            We are <span className="font-semibold">reliable</span>, <span className="font-semibold">creative</span> and 
                            <span className="font-semibold"> consistent</span> in our delivery.
                            Our clients include: <span className="font-semibold">the government</span>, 
                            <span className="font-semibold"> multinationals</span>, <span className="font-semibold"> Education & Religious Organizations</span>, 
                            <span className="font-semibold"> Top notch event organizers and caterers</span>, <span className="font-semibold">prestigious venues</span>, and of course
                            <span className="font-semibold"> everyday private individuals</span>.
                        </p>
                        <div className='md:flex gap-3 md:items-center rounded-md border p-3 hover:shadow'>
                            <div className='md:w-3/12'>
                                <div>
                                    <div className="flex justify-center mb-2">
                                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                                            <GiStarsStack className='h-6 w-6'/>
                                        </div> 
                                    </div>
                                </div>
                                <p className="text-center font-bold text-2xl text-primary" style={{'color':'#852595ff'}}>Our Mission</p>
                            </div>
                            <div className='md:w-9/12'>
                                <p className='text-center md:text-start'>
                                    To provide high quality event management solutions through memorable partnership and resources solutions. 
                                    We position ourselves as both a resource <span className="font-semibold">( by offering a complete package from our equipment lines ) </span>
                                    and partner <span className="font-semibold">( working with other vendors )</span> by offering great solutions to make the 
                                    event memorably, unforgettable! 
                                </p>
                            </div>
                        </div>
                        <div className='md:flex gap-3 md:items-center pt-6 rounded-md border p-3 hover:shadow'>
                            <div className='md:w-3/12'>
                                <div className='text-center md:text-start'>
                                    <div className="flex justify-center mb-2">
                                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                                            <FaCogs className='h-6 w-6'/>
                                        </div> 
                                    </div>
                                </div>
                                <p className="text-center font-bold text-2xl text-primary" style={{'color':'#852595ff'}}>How?</p>
                            </div>
                            <div className='md:w-9/12'>
                                <p className='text-center md:text-start'>
                                    Through flawless execution of project implementation and within budget. We uphold <span className="font-semibold">high integrity</span> , 
                                    <span className="font-semibold"> ethics</span> and <span className="font-semibold"> professionalism</span> in respect for all cultures in all business interactions.
                                </p>
                            </div>
                        </div>
                        <div className='md:flex gap-3 md:items-center pt-6 rounded-md border p-3 hover:shadow'>
                            <div className='md:w-3/12 '>
                                <div>
                                    <div className="flex justify-center mb-2">
                                        <div className="inline-block py-4 px-4 leading-none font-bold text-white rounded-full bg-primary text-xl" style={{'backgroundColor': '#852595ff'}}>
                                            <FaRegCircleQuestion className='h-6 w-6'/>
                                        </div> 
                                    </div>
                                </div>
                                <p className="text-center font-bold text-2xl text-primary" style={{'color':'#852595ff'}}>Why Oriental?</p>
                            </div>
                            <div className='md:w-9/12 decorated__list'>
                                <ul className='text-center md:text-start '>
                                    <li>We commit to know & understand client requirements.</li>
                                    <li>We offer cost effective plans while maintaining quality.</li>
                                    <li>We offer customised solutions.</li>
                                    <li>Client satisfaction is a gain.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center py-7'>
                    <Link to='/gallery' className='btn btn-primary btn-wide uppercase' style={{'backgroundColor': '#852595ff'}}><RiGalleryFill/> View our Gallery</Link>
                </div>
                <div className='py-7'>
                    <GetUs/>
                </div>
                <div className='py-7'>
                    <PreviousClients/>
                </div>
                <a href="https://github.com/gflexx" target='_blank' rel="noreferrer">
                    <div className="flex mt-12 gap-3 items-center hover:text-primary ml-9">
                        <GiAutoRepair className='h-7 w-7 text-accent'/>
                        <p>Designed and Developed by  gflexx</p>
                    </div>
                </a>
            </div>
        </HelmetProvider>
    )
}

export default AboutPage