import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IoMdHome } from "react-icons/io"
import { IoMdInformationCircle } from "react-icons/io"
import { RiGalleryFill } from "react-icons/ri"
import { FaPenFancy } from "react-icons/fa"
import logo from '../../assets/logo_short.png'
import {BsTelephoneFill} from 'react-icons/bs'
import {IoIosMailOpen} from 'react-icons/io'
import { FaLocationDot } from "react-icons/fa6"

function Drawer() {
    const location = useLocation()
    return (
        <div className="drawer-side z-50">
            <label htmlFor="nav-drawer" className="drawer-overlay"></label>
            <div className="p-4 w-64 bg-base-100 h-full">
                <div className="flex flex-col">
                    <ul className="menu">
                        <li>
                            <Link 
                                to='/' 
                                className={location.pathname === '/' ? 'active': ''}
                                ><IoMdHome/> Home</Link>
                        </li>
                        <li>
                            <Link 
                                to='/about' 
                                className={location.pathname === '/about' ? 'active': ''}
                                ><IoMdInformationCircle/> About</Link>
                        </li>
                        <li>
                            <Link 
                                to='/gallery' 
                                className={location.pathname === '/gallery' ? 'active': ''}
                                ><RiGalleryFill/> Gallery</Link>
                        </li>
                        <li>
                            <Link 
                                to='/contact' 
                                className={location.pathname === '/contact' ? 'active': ''}
                                ><FaPenFancy/> Contact Us</Link>
                        </li>
                    </ul>
                    <div className=' mt-20'>
                        <div className="flex justify-center">
                            <img className='h-16 w-auto' src={logo} alt="logo" />
                        </div>
                    </div>
                </div>
                <div className='text-sm flex flex-col gap-3 mt-20 md:hidden'>
                    <div className='flex gap-3 border p-3 rounded-md items-center'>
                        <div className="border p-2 rounded-md grow-0 border-accent">
                            <BsTelephoneFill className='h-6 w-6 text-accent'/>
                        </div>
                        <div className='grow'>
                            <p className='font-bold'>Phone:</p>
                            <p>0721817980</p>
                            <p>0733817980</p>
                        </div>
                    </div>
                    <div className='flex gap-3  border p-3 rounded-md items-center'>
                        <div className="border p-2 rounded-md grow-0 border-accent">
                            <IoIosMailOpen className='h-6 w-6 text-accent'/>
                        </div>
                        <div className='grow'>
                            <p className='font-bold'>Email:</p>
                            <p>info@oriental-occasions.co.ke</p>
                        </div>
                    </div>
                    <div className='flex gap-3 border p-3 rounded-md items-center'>
                        <div className="border p-2 rounded-md grow-0 border-accent">
                            <FaLocationDot className='h-6 w-6 text-accent'/>
                        </div>
                        <div className='grow'>
                            <p>Kisumu,</p>
                            <p>Off Jairo Street, Kimute Est.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Drawer