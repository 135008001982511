const siteName = "Oriental Occassions"

const onProduction = true

const baseUrl = onProduction ? 'https://www.oriental-occasions.co.ke/' : 'http://127.0.0.1:8000'

const getPageValue = (url) =>{
    const urlString = url.split("?")[1]
    const param = new URLSearchParams(urlString)
    return param.get("page")
}

const getCurrentPage = (previous, next) => {
    if (next !== null) {
        const nextPage = getPageValue(next)
        return parseInt(nextPage) - 1
    } else {
        if (previous !== null) {
            const previousPage = getPageValue(previous)
            if(isNaN(parseInt(previousPage))){
                return 2
            }
            return parseInt(previousPage) + 1
        }
    }
}

const toasterPoisition = "bottom-center"

const toasterOptions = {
    className: "rounded-md border"
}

const utils = {
    siteName,
    onProduction,
    baseUrl,
    getPageValue,
    getCurrentPage,
    toasterOptions,
    toasterPoisition,
}

export default utils