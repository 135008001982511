import apiClient from './AxiosHttp'


const getImages = (params) => {
    return apiClient.get(`images/${params}`)
}

const addContact = (details) => {
    return apiClient.post('contact/', details)
}


const imageServices = {
    getImages,
    addContact,
}

export default imageServices