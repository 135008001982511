import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { useSearchParams } from 'react-router-dom'
import imageServices from '../services/ImageServices'
import Loading from '../components/feedback/Loading'
import Error from '../components/feedback/Error'
import Empty from '../components/feedback/Empty'
import Pagination from '../components/nav/Pagination'

function GalleryPage() {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [images, setImages] = useState()
    const [searchparams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setLoading(true)
        const params = searchparams.toString()
        imageServices.getImages(params ? `?${params}` : '').then(
            res => {
                setImages(res.data)
                setLoading(false)
            }, err => {
                setError(err)
                setLoading(false)
            }
        )
    }, [searchparams])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {
        if (images) {
            const top = document.getElementById("top")
            top.scrollIntoView(true, {behavour: 'smooth'})
        }
    }, [images])

    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Gallery - {utils.siteName}</title>
            </Helmet>
            <div className='mx-4 md:mx-5 lg:mx-7'>
                <h1 id='top' className="text-center text-4xl mb-6 text-secondary">Gallery</h1>
                <div className='mx-4 md:mx-5 lg:mx-7'>
                    {images && (
                        <p className='text-lg text-center mb-8'>Total Images: {images?.count}</p>
                    )}                    
                </div>
                {loading ? (<Loading/>):(
                    <div>
                        {error ? (<Error error={error}/>):(
                            <div>
                                {images.count > 0 ? (
                                    <div>
                                        <ResponsiveMasonry
                                            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1080: 4}}>
                                            <Masonry gutter='7.2px'>
                                                {images?.results.map((item, key) => (
                                                    <div key={key}>
                                                        <img className="rounded-md" src={item?.image} alt="event and catering"/>
                                                    </div>
                                                ))}
                                            </Masonry>
                                        </ResponsiveMasonry>
                                        {((images?.next !== null) || (images?.previous !== null)) && (
                                            <Pagination
                                                next={images?.next}
                                                previous={images?.previous}
                                                changePageParam={changePageParam}
                                                isAdmin={false}
                                            />
                                        )}
                                    </div>
                                ):(
                                    <Empty item={"Photos"}/>
                                )}
                            </div>
                        )}
                    </div>
                )}
                
            </div>
        </HelmetProvider>
    )
}

export default GalleryPage