/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import logo from '../../assets/logo_short.png'
import { Link } from 'react-router-dom'
import {BsFacebook, BsTelephoneFill} from 'react-icons/bs'
import utils from '../../utils'
import {IoIosMailOpen} from 'react-icons/io'
import { FaInstagram } from "react-icons/fa"
import { FaLocationDot } from "react-icons/fa6"

function Footer() {
    return (
        <div className='mt-20'>
            <div className="py-5 bg-secondary"></div>
            <div className='bg-neutral text-neutral-content pb-16'>
                <div className="grid md:grid-cols-3 gap-2 pt-5">
                    <div>
                        <div className="flex flex-col">
                            <div className="flex justify-center">
                                <img className="h h-36 w-36 md:h-52 md:w-52" src={logo} alt="logo" />
                            </div>
                        </div>
                        <p className="text-1xl text-gray-400 text-center flex-wrap">Providers of Event Management Solutions, Leasing of Equipment & your Entertainment Partner.</p>
                    </div>
                    <div>
                        <p className='text-accent text-lg md:text-2xl text-center hover:text-info uppercase'>Important Links</p>
                        <div className='mt-5 space-y-2 text-center'>
                            <p><Link to="/" className="text-white hover:text-secondary">Home</Link></p>
                            <p><Link to="/gallery" className="text-white hover:text-secondary">Gallery</Link></p>
                            <p><Link to="/about" className="text-white hover:text-secondary">About Us</Link></p>
                            <p><Link to="/contact" className="text-white hover:text-secondary">Contact Us</Link></p>
                        </div>
                        <p className="text-accent text-lg md:text-2xl text-center hover:text-info uppercase mt-7 mb-3">Location</p>
                        <span className='flex text-base-100 hover:text-primary space-x-3 justify-center items-center'>
                            <div>
                                <FaLocationDot className='h-7 w-7 text-accent'/>
                            </div>
                            <div className='space-y-2'>
                                <p className="text-white hover:text-secondary">Kenya, Kisumu,</p>
                                <p className="text-white hover:text-secondary">Off Jairo Street, Kimute Est.</p>
                            </div>
                        </span>
                    </div>
                    <div>
                        <p className='text-accent text-lg md:text-2xl text-center hover:text-info uppercase'>Get Social</p>
                        <div className="space-y-2 mt-5 text-center">
                            <span className='flex text-white hover:text-secondary space-x-3 justify-center items-center'>
                                <div>
                                    <BsFacebook className='h-6 w-6 text-accent'/>
                                </div>
                                <p>{utils.siteName}</p>
                            </span>
                            <span className='flex text-white hover:text-secondary space-x-3 justify-center items-center'>
                                <div>
                                    <FaInstagram className='h-6 w-6 text-accent'/>
                                </div>
                                <p>{utils.siteName}</p>
                            </span>
                        </div>
                        <p className="text-accent text-lg md:text-2xl text-center hover:text-info uppercase mt-5 mb-3">Telephone</p>
                        <span className='flex text-base-100 hover:text-primary space-x-3 justify-center items-center'>
                            <div>
                                <BsTelephoneFill className='h-7 w-7 text-accent'/>
                            </div>
                            <div className='space-y-2'>
                                <p className="text-white hover:text-secondary">+254721817980</p>
                                <p className="text-white hover:text-secondary">+254724837227</p>
                            </div>
                        </span>
                        <p className="text-accent text-lg md:text-2xl text-center hover:text-info uppercase mt-5 mb-3">Email</p>
                        <span className='flex text-base-100 hover:text-primary space-x-3 justify-center items-center'>
                            <div>
                                <IoIosMailOpen className='h-7 w-7 text-accent'/>
                            </div>
                            <div className='space-y-2'>
                                <p className="text-white hover:text-secondary">info@oriental-occasions.co.ke </p>
                                <p className="text-white hover:text-secondary">linda@orientaloccasions.co.ke </p>
                                <p className="text-white hover:text-secondary">bob@orientaloccasions.co.ke</p>
                            </div>
                        </span>
                    </div>                    
                </div>
            </div>
        </div>
    )
}

export default Footer